<template>
  <v-container class="fast-search">
    <CategoryTitle :category="category" />

    <div class="text-body-1 mb-2">
      {{ $t("fastSearch.searchSummary") }}
      <span v-for="(q, index) in query" :key="index">
        <router-link :to="{ name: 'Search', query: { q: q } }">{{
          q
        }}</router-link
        >{{
          index == query.length - 2
            ? $t("fastSearch.and")
            : index == query.length - 1
            ? "."
            : ",&nbsp;"
        }}</span
      >
    </div>
    <div class="search-result" v-for="(q, index) in query" :key="index">
      <div
        class="searched-word-header d-flex align-center flex-column flex-sm-row mb-5 mb-sm-2"
      >
        <div class="searched-word-title">
          <h3 v-html="$t('fastSearch.searchResult', [q])"></h3>
        </div>
        <v-spacer />
        <div>
          <router-link :to="{ name: 'Search', query: { q: q } }">
            {{ $t("fastSearch.showAllProducts") }}
          </router-link>
        </div>
      </div>
      <ProductListSlider
        :query="q"
        :paginationClass="'swiper-pagination-' + index"
        cols="auto"
        sm="2"
        md="2"
        lg="3"
        xl="4"
      >
      </ProductListSlider>
      <!-- <v-divider class="mb-6"></v-divider> -->
    </div>
  </v-container>
</template>
<style lang="scss">
.fast-search {
  .search-result {
    padding-bottom: 15px;
    margin-bottom: 18px;
    border-bottom: 1px solid var(--v-grey-lighten1);
  }
}
</style>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

export default {
  name: "FastSearch",
  components: {
    ProductListSlider,
    CategoryTitle
  },
  mixins: [categoryMixins],
  data() {
    return {
      query: []
    };
  },
  methods: {
    getQueryParams() {
      this.query = this.$route.query.q;
    }
  },
  async created() {
    this.getQueryParams();
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
    }
  }
};
</script>
